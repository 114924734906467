.Footer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: calc(var(--text-4xl) * 2) var(--text-4xl);
  background-color: var(--black-surface);
  z-index: 2;
}

.Footer .container {
  width: 100%;
  max-width: var(--md);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--text-base);
}

.Footer,
.Footer a {
  color: var(--background-white);
}

.Footer section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.Footer section.right {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.Footer section.left a {
  display: flex;
  justify-content: space-between;

  font-size: 400;
  font-weight: 500;
  width: 200px;
  padding-bottom: calc(var(--text-base) / 2);
  margin-bottom: calc(var(--text-xl) / 1);
  border-bottom: 1px #ededed54 solid;
}

.Footer section.right p {
  text-align: end;
  opacity: 33%;

  margin-top: var(--text-6xl);
  padding-bottom: calc(var(--text-base) / 2);
  margin-bottom: calc(var(--text-xl) / 1);
}

.Footer section.right > a img {
  height: 24px;
}

@media (width <= 425px) {
  .Footer .container {
    display: flex;
    flex-direction: column;
    gap: var(--text-6xl);
  }
  .Footer section.right {
    display: flex;
    align-items: flex-start;
  }
  .Footer section.right p {
    margin-top: var(--text-xl);
    text-align: start;
  }
}
