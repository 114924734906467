@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@-moz-viewport {
  width: device-width;
  scale: 1;
}
@-ms-viewport {
  width: device-width;
  scale: 1;
}
@-o-viewport {
  width: device-width;
  scale: 1;
}
@-webkit-viewport {
  width: device-width;
  scale: 1;
}
@viewport {
  width: device-width;
  scale: 1;
}

html {
  background-color: var(--black);
  color: var(--white);

  box-sizing: border-box;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  font-smoothing: antialiased;

  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: 400;
}

a,
a:link,
a:active,
a:visited {
  color: var(--white);
  text-decoration: unset;
}

/* reset button styling */
button {
  background-color: transparent;
  color: var(--white);
  border-width: 0;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
}

:root {
  /* https://coolors.co/f1f1f1-4db5f0-9b5de5-f15bb5-f2545b */
  --background-white: #f1f1f1;

  --white: #eef3f5;
  --black: #0f1010;
  --black-surface: #161818;
  --blue: #4db5f0;
  --purple: #9b5de5;
  --pink: #f15bb5;
  --red: #f2545b;

  /* https://tailwindcss.com/docs/font-size */
  --text-xs: 0.75rem; /* 12px */
  --text-sm: 0.875rem; /* 14px */
  --text-base: 1rem; /* 16px */
  --text-lg: 1.125rem; /* 18px */
  --text-xl: 1.25rem; /* 20px */
  --text-2xl: 1.5rem; /* 24px */
  --text-3xl: 1.875rem; /* 30px */
  --text-4xl: 2.25rem; /* 36px */
  --text-5xl: 3rem; /* 48px */
  --text-6xl: 3.75rem; /* 60px */
  --text-7xl: 4.5rem; /* 72px */
  --text-8xl: 6rem; /* 96px */
  --text-9xl: 8rem; /* 128px */

  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;
  --2xl: 1536px;
}
