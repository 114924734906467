/* .Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.Home > .container {
  /* background-color: #4b68c03a; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 var(--text-4xl);
}

.Home > .container main {
  /* background-color: #4b68c03a; */
  width: 100%;
  max-width: var(--2xl);
  display: flex;
  align-items: center;
  justify-content: center;
}
