.Projects {
  /* background-color: rgba(34, 0, 255, 0.32); */
  width: 100%;
  max-width: var(--md);

  padding-top: 90vh;
  padding-bottom: calc(var(--text-9xl) * 2);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--text-base);
  /* overflow-y: auto; */
}

.Projects .hoverContainer:nth-of-type(-n + 1),
.Projects .hoverContainer:nth-of-type(4n) {
  grid-column: span 2;
}

.Projects .hoverContainer:nth-of-type(2),
.Projects .hoverContainer:nth-of-type(3n) {
  aspect-ratio: 1 / 1.66;
}

.Projects .hoverContainer {
  width: 100%;
  height: 100%;
}

.Projects .project {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background-color: grey;
  color: var(--black);
  border-radius: var(--text-base);
  padding: var(--text-3xl);
  /* box-shadow: 0px 32px 128px var(--black); */
}

.Projects .project .title {
  display: flex;
}

.Projects .project .title p {
  font-size: var(--text-xl);
  font-weight: 600;
  margin-right: calc(var(--text-base) / 2);
}

.Projects .project .title {
  margin-bottom: calc(var(--text-base) / 2);
}

.Projects .project .chips {
  display: flex;
  align-items: center;
  margin-bottom: calc(var(--text-base) / 2);
  gap: calc(var(--text-base) / 2);
}

.Projects .project .chips p {
  font-size: var(--text-xs);
  padding: calc(var(--text-xs) / 2) var(--text-xs);
  border: 1px var(--black) solid;
  border-radius: 99px;
}

.Projects .project > p {
  font-size: var(--text-base);
}

.Projects #SmartTenant {
  color: var(--black);
}
.Projects #SmartTenant .chips p {
  border-color: var(--black);
}

/* card backgrounds */

.Projects #Vuzec {
  z-index: 8;

  background-image: url(../../../img/portfolioAssets/vuzec_gradient.png);

  background-position: center center;
  background-size: cover;
}
.Projects #Applauz {
  z-index: 6;

  background-image: url(../../../img/portfolioAssets/applauz_gradient.png);

  background-position: center center;
  background-size: cover;
}
.Projects #Fiverr {
  z-index: 5;

  background-image: url(../../../img/portfolioAssets/fiverr_gradient.png);

  background-position: center center;
  background-size: cover;
}
.Projects #SmartTenant {
  z-index: 7;

  z-index: 5;

  background-image: url(../../../img/portfolioAssets/smartTenant_gradient.png);

  background-position: center center;
  background-size: cover;
}
